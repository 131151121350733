.top_select[data-v-6e3e3c98] {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title[data-v-6e3e3c98] {
  font-size: 14px;
  border-left: 3px solid var(--themeColor, #17a2b8);
  padding-left: 5px;
  margin-bottom: 10px;
}
.hint[data-v-6e3e3c98] {
  font-size: 14px;
  margin-top: 20px;
}
.el-radio[data-v-6e3e3c98] {
  margin-top: 10px;
}
.shu_left[data-v-6e3e3c98] {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.el-radio[data-v-6e3e3c98] {
  margin-right: 10px !important;
}
.main[data-v-6e3e3c98] {
  width: 100%;
  height: calc(100vh - 96px);
  position: relative;
  display: flex;
  align-items: center;
}
.main_left[data-v-6e3e3c98] {
  width: 45%;
  height: 100%;
  background-image: linear-gradient(to right, #eff9fb, #fefeff);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_left img[data-v-6e3e3c98] {
  width: 90%;
}
.main_right[data-v-6e3e3c98] {
  float: left;
}
.main_right h3[data-v-6e3e3c98] {
  margin-bottom: 15px;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
}
.main_right h5[data-v-6e3e3c98] {
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
}
.pic[data-v-6e3e3c98] {
  width: 150px;
  position: absolute;
  top: 0;
  right: 0;
}
.pic img[data-v-6e3e3c98] {
  width: 100%;
}
.each_content[data-v-6e3e3c98] {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.each_content .el-icon[data-v-6e3e3c98] {
  font-size: 20px;
  color: #5aca8b;
  margin-right: 3px;
}
.each_content p[data-v-6e3e3c98] {
  color: #474747;
  font-size: 14px;
}